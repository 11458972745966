import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { breakpoints } from "../assets/globalStyles"
import Button from "./Button"
import PinkChevronIcon from "./../assets/icons/pink-chevron.svg"
import InternalLink from "./InternalLink"
import Spacer from "./Spacer"
import CaseStudyBox from "./CaseStudyBox"

const StyledFeaturedProjects = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    h2 {
      margin-bottom: 0;
    }

    .desktop-view-more {
      display: none;
      @media screen and (min-width: ${breakpoints.md}px) {
        display: inline-flex;
      }
    }
  }

  .mobile-view-more {
    @media screen and (min-width: ${breakpoints.md}px) {
      display: none;
    }
  }
`

export default function FeaturedProjects({
  caseStudies,
  title,
  showLinkToAllCaseStudies,
}) {
  return (
    <StyledFeaturedProjects>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title">
              <h2>{title}</h2>
              {showLinkToAllCaseStudies && (
                <InternalLink to="/work/" className="desktop-view-more">
                  View All Case Studies
                </InternalLink>
              )}
            </div>
            <Spacer size={24} />
          </div>
        </div>
        <div className="row">
          {caseStudies.map((caseStudy, index) => {
            if (index === 0 && caseStudies.length > 2) {
              return (
                <div className="col-12" key={caseStudy.id}>
                  <CaseStudyBox caseStudy={caseStudy} tall />
                  <Spacer size={24} />
                </div>
              )
            } else if (
              (index === 1 && caseStudies.length > 2) ||
              (caseStudies.length === 2 && index === 0)
            ) {
              return (
                <div className="col-12 col-md-6 col-lg-8" key={caseStudy.id}>
                  <CaseStudyBox caseStudy={caseStudy} />
                  <Spacer size={24} when={{ md: 0 }} />
                </div>
              )
            } else {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={caseStudy.id}>
                  <CaseStudyBox caseStudy={caseStudy} />
                  <Spacer size={24} when={{ md: 0 }} />
                </div>
              )
            }
          })}
        </div>
        {showLinkToAllCaseStudies && (
          <div className="row">
            <div className="col-12">
              <Button
                to="/work/"
                as={Link}
                className="mobile-view-more"
                variant="outline"
                colour="primary"
                icon={
                  <img
                    src={PinkChevronIcon}
                    style={{
                      height: "16px",
                      marginLeft: "8px",
                    }}
                    alt=""
                  />
                }
              >
                View All Case Studies
              </Button>
            </div>
          </div>
        )}
      </div>
    </StyledFeaturedProjects>
  )
}
