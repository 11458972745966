import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { breakpoints } from "../assets/globalStyles"
import QuoteOpen from "./../assets/images/quote-open.svg"
import QuoteClose from "./../assets/images/quote-close.svg"

const StyledTestimonailWrapper = styled.div`
  background-color: #eef1f4;
  padding: 64px 0 40px;
  overflow: hidden;
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 80px 0 40px;
  }
  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 80px 0 80px;
  }
  .quote-wrapper {
    .quote {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 16px;
      position: relative;
      @media screen and (min-width: ${breakpoints.lg}px) {
        font-size: 34px;
        line-height: 52px;
      }
    }

    &:before {
      content: "";
      background-image: url(${QuoteOpen});
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      width: 26px;
      height: 16px;
      top: -32px;
      background-size: contain;
      @media screen and (min-width: ${breakpoints.md}px) {
        height: 32px;
        width: 52px;
        top: -40px;
        left: 0px;
      }
      @media screen and (min-width: ${breakpoints.lg}px) {
        height: 102px;
        width: 165px;
        left: -195px;
        top: -100px;
      }
    }

    &:after {
      content: "";
      background-image: url(${QuoteClose});
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      width: 26px;
      height: 16px;
      right: 15px;
      bottom: 10px;
      @media screen and (min-width: ${breakpoints.md}px) {
        height: 32px;
        width: 52px;
        right: 15px;
      }

      @media screen and (min-width: ${breakpoints.lg}px) {
        height: 102px;
        bottom: -100px;
        width: 165px;
        right: -150px;
      }
    }

    .info {
      color: #3f4958;
      font-family: Roboto;
      font-size: 18px;
      line-height: 28px;
    }
  }
`

export default function Testimonial() {
  const {
    allWpTestimonial: { nodes: testimonials },
  } = useStaticQuery(
    graphql`
      query {
        allWpTestimonial {
          nodes {
            testimonialFields {
              name
              quote
            }
          }
        }
      }
    `
  )

  // Get a random quote
  const { quote, name } = testimonials[
    Math.floor(Math.random() * testimonials.length)
  ].testimonialFields

  return (
    <StyledTestimonailWrapper>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="quote-wrapper">
              <p className="quote">{quote}</p>
              <p className="info">-{name}</p>
            </div>
          </div>
        </div>
      </div>
    </StyledTestimonailWrapper>
  )
}
