import React, { Fragment, useState } from "react"
import { animated, useSprings } from "react-spring"
import styled from "styled-components"
import { breakpoints } from "../assets/globalStyles"
import { GatsbyImage } from "gatsby-plugin-image"

import Spacer from "./Spacer"
import UpIcon from "./../assets/icons/up-arrow.svg"

const StyledMobileTabs = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }
`

const StyledMobileContent = styled.div`
  border: solid 1px #ebebeb;
  border-left: ${({ colour }) => `solid 8px ${colour}`};
  padding: 16px;
  overflow: hidden;
  .title-box {
    display: flex;
    align-items: center;
    h2 {
      font-weight: 400;
      margin-bottom: 0 !important;
    }
    p {
      font-size: 18px !important;
    }

    .dropdown-toggle {
      fill: #66768e;
      opacity: 0.6;
      transition: transform 350ms ease-in-out;
      margin-left: auto;
      transform: ${({ open }) => {
        if (open) {
          return "rotate(0deg)"
        } else {
          return "rotate(180deg)"
        }
      }};
    }
  }

  .content {
    overflow: auto;
    p {
      padding-top: 16px;
    }
  }
`

const StyledDekstopContent = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.lg}px) {
    display: flex;
  }
  position: relative;
  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    .content {
      grid-row-start: 1;
      grid-column-start: 1;
      h2 {
        font-weight: 400;
        line-height: 36px;
      }
      p {
        font-size: 20px !important;
        line-height: 32px !important;
      }
    }
  }
`

const StyledDesktopTab = styled.div`
  border: solid 1px #ebebeb;
  padding: 24px 24px 24px 8px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  .coloured-line {
    background-color: ${({ colour }) => colour};
    display: block;
    position: absolute;
    width: 8px;
    top: 0;
    height: 100%;
  }
  h2 {
    font-weight: 400;
    margin-bottom: 0 !important;
    font-size: 20px;
    line-height: 32px;
  }
  p {
    font-size: 16px !important;
    line-height: 24px !important;
  }
`

export default function TabbedContent({ tabs }) {
  const [visibleTabs, setVisibleTabs] = useState([])
  const [desktopTab, setDesktopTab] = useState(0)
  const [desktopTabHovered, setDesktopTabHovered] = useState()

  // Get animation properties
  const animations = useSprings(
    tabs.length,
    tabs.map((tab, index) => ({
      maxHeight: visibleTabs.includes(index) ? "750px" : "0px",
    }))
  )

  const desktopTabAnimations = useSprings(
    tabs.length,
    tabs.map((tab, index) => ({
      left:
        index === desktopTabHovered || index === desktopTab ? "0px" : "-8px",
      config: {
        tension: 250,
        friction: 12,
        clamp: true,
      },
    }))
  )

  const desktopContentAnimations = useSprings(
    tabs.length,
    tabs.map((tab, index) => ({
      transform:
        index === desktopTab
          ? "translateX(0%) scale(1)"
          : "translateX(35%) scale(0.8)",
      opacity: index === desktopTab ? "1" : "0",
      config: {
        tension: 250,
        friction: 20,
        clamp: false,
      },
    }))
  )

  //combine animation properties with tabs
  tabs = tabs.map((tab, index) => ({
    ...tab,
    animationProps: animations[index],
    desktopTabAnimationProps: desktopTabAnimations[index],
    desktopContentAnimationProps: desktopContentAnimations[index],
  }))

  return (
    <div className="container">
      <StyledMobileTabs className="row">
        {tabs.map(
          (
            { colour, content, title, summary, icon, animationProps },
            index
          ) => {
            const isLast = index === tabs.length - 1

            return (
              <div className="col-12" key={index}>
                <StyledMobileContent
                  open={visibleTabs.includes(index)}
                  colour={colour}
                  onClick={() => {
                    if (visibleTabs.includes(index)) {
                      // remove from array
                      setVisibleTabs(
                        visibleTabs.filter((tabIndex) => tabIndex !== index)
                      )
                    } else {
                      setVisibleTabs([...visibleTabs, index])
                    }
                  }}
                >
                  <div className="title-box">
                    {icon && (
                      <>
                        {icon.mimeType === "image/svg+xml" ? (
                          <img src={icon.localFile.publicURL} alt={title} />
                        ) : (
                          <GatsbyImage
                            image={
                              icon.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={title}
                            objectFit="contain"
                            style={{
                              maxHeight: "100%",
                              height: "100%",
                              width: "64px",
                            }}
                          />
                        )}
                        <Spacer size={16} />
                      </>
                    )}
                    <div>
                      <h2>{title}</h2>
                      <p>{summary}</p>
                    </div>
                    <img src={UpIcon} className="dropdown-toggle" alt="" />
                  </div>
                  <animated.div
                    className="content"
                    style={animationProps}
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                </StyledMobileContent>
                {!isLast && <Spacer size={24} />}
              </div>
            )
          }
        )}
      </StyledMobileTabs>
      <StyledDekstopContent className="row">
        <div className="col-lg-7 content-wrapper">
          {tabs.map(
            ({ title, content, desktopContentAnimationProps }, index) => (
              <animated.div
                className="content"
                key={index}
                style={desktopContentAnimationProps}
              >
                <h2>{title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </animated.div>
            )
          )}
        </div>
        <div className="col-lg-4 offset-lg-1">
          {tabs.map(
            (
              { colour, title, summary, icon, desktopTabAnimationProps },
              index
            ) => {
              const isLast = index === tabs.length - 1

              return (
                <Fragment key={index}>
                  <StyledDesktopTab
                    colour={colour}
                    onClick={() => {
                      setDesktopTab(index)
                    }}
                    onMouseEnter={() => {
                      setDesktopTabHovered(index)
                    }}
                    onMouseLeave={() => {
                      setDesktopTabHovered()
                    }}
                  >
                    <animated.span
                      style={desktopTabAnimationProps}
                      className="coloured-line"
                    />
                    <Spacer size={24} />
                    {icon && (
                      <>
                        {icon.mimeType === "image/svg+xml" ? (
                          <img src={icon.localFile.publicURL} alt={title} />
                        ) : (
                          <GatsbyImage
                            image={
                              icon.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={title}
                            objectFit="contain"
                            style={{
                              maxHeight: "100%",
                              height: "100%",
                              width: "64px",
                            }}
                            onMouseEnter={() => {
                              setDesktopTabHovered(index)
                            }}
                            onMouseLeave={() => {
                              setDesktopTabHovered()
                            }}
                          />
                        )}
                        <Spacer size={16} />
                      </>
                    )}
                    <div>
                      <h2>{title}</h2>
                      <Spacer size={8} />
                      <p>{summary}</p>
                    </div>
                  </StyledDesktopTab>
                  {!isLast && <Spacer size={16} />}
                </Fragment>
              )
            }
          )}
        </div>
      </StyledDekstopContent>
    </div>
  )
}
