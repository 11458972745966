import React, { useState } from "react"
import SlickSlider from "react-slick"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"

import Spacer from "./Spacer"
import PaginationIcon from "./../assets/icons/pagination-arrow.svg"

const PaginationArrow = styled.span`
  position: absolute;
  bottom: -48px;
  cursor: pointer;
  height: 28px;
  width: 17px;
  display: block;
  background-image: url(${PaginationIcon});
  left: ${({ arrow }) => {
    if (arrow === "next") {
      return "56px"
    }

    // This is the next arrow
    return "8px"
  }};
`

export default function Slider({ slides, ...rest }) {
  const [isPrevHovered, setIsPrevHovered] = useState(false)
  const [isNextHovered, setIsNextHovered] = useState(false)

  const AnimatedPrevPagination = animated(PaginationArrow)

  const prevPaginationAnimation = useSpring({
    transform: isPrevHovered ? "translateX(-5px)" : "translateX(0px)",
    config: {
      tension: 250,
      friction: 15,
    },
  })

  function PrevArrow({ onClick }) {
    return (
      <AnimatedPrevPagination
        onClick={onClick}
        onKeyDown={onClick}
        arrow="prev"
        onMouseEnter={() => {
          setIsPrevHovered(true)
        }}
        onMouseLeave={() => {
          setIsPrevHovered(false)
        }}
        style={prevPaginationAnimation}
      />
    )
  }

  const AnimatedNextPagination = animated(PaginationArrow)

  const nextPaginationAnimation = useSpring({
    transform: isNextHovered
      ? "translateX(5px) rotate(180deg)"
      : "translateX(0px) rotate(180deg)",
    config: {
      tension: 250,
      friction: 15,
    },
  })

  function NextArrow({ onClick }) {
    return (
      <AnimatedNextPagination
        onClick={onClick}
        onKeyDown={onClick}
        arrow="next"
        style={nextPaginationAnimation}
        onMouseEnter={() => {
          setIsNextHovered(true)
        }}
        onMouseLeave={() => {
          setIsNextHovered(false)
        }}
      />
    )
  }

  return (
    <>
      <SlickSlider
        infinite={true}
        speed={500}
        slidesToScroll={1}
        arrows={true}
        autoplay={false}
        swipeToSlide={true}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        dots={false}
        {...rest}
      >
        {slides.map((slide) => slide)}
      </SlickSlider>
      <Spacer size={48} />
    </>
  )
}
